import React, { Component, createRef } from 'react';
import { Container } from '~/ui';

class UIHtmlDoc extends Component {
  render() {
    return (
      <Container smallStyle={this.props.smallStyle} style={this.props.style}>
        <iframe
          ref={this.iframe}
          style={{ 
            height: '100%', 
            width: '100%', 
            maxWidth: '100%',
            border: 0,
          }}
          src={this.props.uri}
        >
        </iframe>
      </Container>
    ); 
  }
} 

export default UIHtmlDoc;
