import { VehicleTypes } from '../../utils/constants'

const initialState = {
  activeTopic: null,
  activeLesson: null,
  activeExercise: null,
  activeSection: null,
  statusBarVisible: true,
  vehicleType: VehicleTypes.car
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_UI':
      return initialState;
    case 'SET_ACTIVE_TOPIC':
      return {
        ...state,
        ...initialState,
        activeTopic: action.topicId,
        vehicleType: state.vehicleType,
      };
    case 'SET_ACTIVE_LESSON':
      return {
        ...state,
        activeLesson: action.lessonId,
        activeSection: null,
        activeExercise: null,
      };
    case 'SET_ACTIVE_SECTION':
      return {
        ...state,
        activeSection: action.sectionId,
        activeExercise: null,
      };
    case 'SET_ACTIVE_EXERCISE':
      return {
        ...state,
        activeExercise: action.exerciseId,
      };
    case 'SET_STATUS_BAR_VISIBILITY':
      return {
        ...state,
        statusBarVisible: action.visible,
      };
    case 'SET_VEHICLE_TYPE':
      return {
        ...state,
        vehicleType: action.vehicleType,
      };
    default:
      return state;
  }
};
