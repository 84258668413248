import { firebaseGetBaseContent, firebaseGetSubscriptionContent } from '~/utils/api';

export const getEntitiesAction = lessonId => dispatch => {
  dispatch({
    type: 'SET_ACTIVE_LESSON',
    lessonId,
  });
};

export const getBaseContentAction = (vehicleType) => dispatch => {
  firebaseGetBaseContent(vehicleType).then(entities => {
    dispatch({
      type: 'GET_BASE_CONTENT',
      payload: entities,
    });
  });
};

export const getSubscriptionEntitiesAction = (vehicleType) => dispatch => {
  dispatch({ type: 'GET_SUBSCRIPTION_CONTENT_START' });
  firebaseGetSubscriptionContent(vehicleType).then(entities => {
    dispatch({
      type: 'GET_SUBSCRIPTION_CONTENT',
      payload: entities,
    });
  });
};

export const skipSubscriptionEntitiesAction = () => ({
  type: 'GET_SUBSCRIPTION_CONTENT_SKIP',
  payload: {},
});
