import { VehicleTypes } from '../constants'

const ContentSuffixByVehicleType = {
  [VehicleTypes.car]: '',
  [VehicleTypes.moped]: '_moped',
  [VehicleTypes.mc]: '_mc',
}

export const getContentSuffixByVehicleType = (vehicleType) => {
  const suffix = ContentSuffixByVehicleType[vehicleType]

  return suffix ?? '';
}