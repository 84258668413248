import React, { memo, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { getRoute } from './routeList';
import { Layout } from '../ui';
import UrlToReduxMapper from '~/components/UrlToReduxMapper';
import { ActiveTopicMenuContent } from '~/components/TopicMenuContent';
import HaltUntilContentLoaded from '~/components/HaltUntilContentLoaded';

const Profile = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Profile'));
const Search = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Search'));
const Topic = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Topic'));
const Exercise = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Exercise'));
const Sections = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Sections'));
const KnowledgeTest = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/KnowledgeTest'));
const Checkout = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Checkout'));
const Confirmation = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/Confirmation'));
const ResetPasswordScreen = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/ResetPassword'));
const SignUpScreen = React.lazy(() => import(/* webpackPrefetch: true */ '../screens/SignUp'));

const LazySearch = props => (
  <Suspense fallback={<div />}>
    <Search {...props} />
  </Suspense>
);
const LazyProfile = props => (
  <Suspense fallback={<div />}>
    <Profile {...props} />
  </Suspense>
);
const LazyTopic = props => (
  <Suspense fallback={<div />}>
    <Topic {...props} />
  </Suspense>
);
const LazyExercise = props => (
  <Suspense fallback={<div />}>
    <Exercise {...props} />
  </Suspense>
);
const LazySections = props => (
  <Suspense fallback={<div />}>
    <Sections {...props} />
  </Suspense>
);
const LazyKnowledgeTest = props => (
  <Suspense fallback={<div />}>
    <KnowledgeTest {...props} />
  </Suspense>
);
const LazyCheckout = props => (
  <Suspense fallback={<div />}>
    <Checkout {...props} />
  </Suspense>
);
const LazyConfirmation = props => (
  <Suspense fallback={<div />}>
    <Confirmation {...props} />
  </Suspense>
);
const LazyResetPasswordScreen = props => (
  <Suspense fallback={<div />}>
    <ResetPasswordScreen {...props} />
  </Suspense>
);
const LazySignUpScreen = props => (
  <Suspense fallback={<div />}>
    <SignUpScreen {...props} />
  </Suspense>
);

const SectionRouter = () => (
  <Layout aside={<ActiveTopicMenuContent />} hasBackButton>
    <Switch fallback={<div>Loading...</div>}>
      <CustomRoute path={getRoute('section').webUrl} exact component={LazySections} private noLayout />
      <CustomRoute
        path={getRoute('exercise').webUrl}
        exact
        component={LazyExercise}
        private
        noLayout
        subscriptionGate
      />
    </Switch>
  </Layout>
);

const WebRouter = (props) => {
  window.scrollTo(0, 0);
  return (
    <div>
      <Switch>
        <CustomRoute path={getRoute('login').webUrl} component={getRoute('login').component} />
        <CustomRoute path={getRoute('signup').webUrl} component={LazySignUpScreen} />
        <CustomRoute path={getRoute('resetPassword').webUrl} component={LazyResetPasswordScreen} />
        <CustomRoute
          path={getRoute('home').webUrl}
          title={getRoute('home').title}
          hasAppBar
          exact
          component={getRoute('home').component}
          hasSearchButton
          hasProfileButton
          private
          hasMdaoToken={props.hasMdaoToken}
        />
        <CustomRoute
          path={getRoute('home_car').webUrl}
          title={getRoute('home_car').title}
          hasAppBar
          exact
          component={getRoute('home_car').component}
          hasSearchButton
          hasProfileButton
          private
          hasMdaoToken={props.hasMdaoToken}
        />
        <CustomRoute
          path={getRoute('home_moped').webUrl}
          title={getRoute('home_moped').title}
          hasAppBar
          exact
          component={getRoute('home_moped').component}
          hasSearchButton
          hasProfileButton
          private
          hasMdaoToken={props.hasMdaoToken}
        />
        <CustomRoute
          path={getRoute('home_mc').webUrl}
          title={getRoute('home_mc').title}
          hasAppBar
          exact
          component={getRoute('home_mc').component}
          hasSearchButton
          hasProfileButton
          private
          hasMdaoToken={props.hasMdaoToken}
        />
        <CustomRoute path={getRoute('topic').webUrl} exact component={LazyTopic} private hasBackButton />
        <CustomRoute
          path={getRoute('knowledgeTest').webUrl}
          title={getRoute('knowledgeTest').title}
          hasProfileButton={false}
          exact
          component={LazyKnowledgeTest}
          private
          subscriptionGate
          mapUrlToRedux={false}
        />
        <Route path="/topics/:topicId/:sectionId" render={() => SectionRouter()} />
        <CustomRoute
          path={getRoute('profile').webUrl}
          title={getRoute('profile').title}
          hasAppBar
          hasBackButton
          hasProfileButton={false}
          exact
          component={LazyProfile}
          private
        />
        <CustomRoute
          path={getRoute('search').webUrl}
          title="Sök"
          hasAppBar
          hasBackButton
          exact
          component={LazySearch}
          hasProfileButton={false}
          hasSearchButton={false}
        />
        <CustomRoute path={getRoute('checkout').webUrl} hasAppBar hasBackButton exact component={LazyCheckout} />
        <CustomRoute
          path={getRoute('confirmation').webUrl}
          hasAppBar
          hasBackButton
          exact
          component={LazyConfirmation}
        />
        <Route path={getRoute('pageNotFound').webUrl} component={getRoute('pageNotFound').component} />
        <Route component={getRoute('pageNotFound').component} />
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ auth: { loggedIn, user } }) => ({
  loggedIn,
  subscriptionValidTo: user.subscriptionData.subscriptionValidTo,
  validSubscription: !!(user && user.validSubscription),
});

const AuthAndSubscriptionCheck = connect(mapStateToProps)(
  memo(({ private: isPrivate, subscriptionGate, loggedIn, validSubscription, hasMdaoToken }) => {
    if (isPrivate && !loggedIn && !hasMdaoToken) {
      return <Redirect from="*" to="/login" />;
    }
    if (subscriptionGate && !validSubscription) {
      return <Redirect from="*" to="/" />;
    }

    return null;
  })
);

const CustomRoute = props => {
  const {
    title,
    hasBackButton,
    aside,
    hasAppBar,
    hasProfileButton,
    hasSearchButton,
    path,
    exact,
    subscriptionGate,
    mapUrlToRedux = true,
    hasMdaoToken = false
  } = props;

  const Comp = props.component;
  const RouteComponent = compProps => {
    const content = (
      <>
        <AuthAndSubscriptionCheck private={props.private} subscriptionGate={subscriptionGate} hasMdaoToken={hasMdaoToken} />
        {props.noLayout ? (
          <Comp {...compProps} />
        ) : (
          <Layout
            title={title}
            hasAppBar={hasAppBar}
            aside={aside}
            hasBackButton={hasBackButton}
            hasProfileButton={hasProfileButton}
            hasSearchButton={hasSearchButton}
          >
            <Comp {...compProps} />
          </Layout>
        )}
      </>
    );
    return (
      <HaltUntilContentLoaded requireSubscribed={subscriptionGate}>
        <AuthAndSubscriptionCheck private={props.private} subscriptionGate={subscriptionGate} hasMdaoToken={hasMdaoToken} />
        {mapUrlToRedux ? <UrlToReduxMapper match={compProps.match}>{content}</UrlToReduxMapper> : content}
      </HaltUntilContentLoaded>
    );
  };

  return <Route path={path} exact={exact} component={RouteComponent} />;
};

CustomRoute.defaultProps = {
  exact: false,
  private: false,
};

export default withRouter(WebRouter);
