import React, { Component } from 'react';
import { render as DOMRender } from 'react-dom';
import { Provider, connect } from 'react-redux';
import { withRouter, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from '~/routes/navigation';
import { store, persistor } from '~/store';
import Routes from '~/routes/routes';
import { authSubscriberAction, authSignInWithCustomToken } from '~/store/actions/authActions';
import { getBaseContentAction } from '~/store/actions/entitiesActions';
import { setVehicleType } from '~/store/actions/uiActions'
import { Container, Loader } from '~/ui';
import loadWebFont from '~/utils/fontHelper';
import ThemeWrapper from '~/ui/theme/ThemeWrapper';
import { getMDAOTokenFromLocation, getVehicleType, setVehicleTypeToLocalStorage } from '~/utils/helpers'

loadWebFont();

class MainNavigationComponent extends Component {
  componentDidMount() {
    const { dispatch, location } = this.props;

    dispatch(authSubscriberAction());

    setVehicleTypeToLocalStorage(location);

    const mdaoToken = getMDAOTokenFromLocation(location);
    const vehicleType = getVehicleType(location);

    if (mdaoToken) 
      dispatch(authSignInWithCustomToken(mdaoToken));

    dispatch(setVehicleType(vehicleType));
    dispatch(getBaseContentAction(vehicleType));

    // eslint-disable-next-line
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js'); // eslint-disable-line
    }
  }

  render() {
    const { loading } = this.props;
    return loading ? (
      <Container wrapWebContent>
        <Loader />
      </Container>
    ) : (
      <Routes hasMdaoToken={Boolean(getMDAOTokenFromLocation(location))} />
    );
  }
}

const mapStateToPropsRoot = ({ auth }) => ({
  loading: auth.loading,
});

const MainNativeNavigation = withRouter(connect(mapStateToPropsRoot)(MainNavigationComponent));

DOMRender(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <ThemeWrapper>
          <MainNativeNavigation />
        </ThemeWrapper>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
